import React from 'react';
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import BannerCrypto from '../components/blockchain/Banner';
import HappyClient from '../components/blockchain/HappyClients';
import WeFocused from '../components/blockchain/WeFocused';
import Popular from '../components/blockchain/Popular';
import OurVarious from '../components/blockchain/OurVarious';
import OurCustom from '../components/blockchain/OurCustom';
import BenifitsOf from '../components/blockchain/BenifitsOf';
import OnthSpot from '../components/blockchain/OntheSpot';
import WhatMake from '../components/blockchain/WhatMake';
import Kickoff from '../components/blockchain/KickOff';
import FaqSection from '../components/blockchain/FaqSection';
import UserFriendly from '../components/Cryptoexchange/UserFriendly';
import IndustriesWe from '../components/blockchain/IndustriesWe';


const BlockChain = () => (
  <section className="cryptoex block-chain">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/blockchain-development-company/" />
        <title>Blockchain Development Company | Coinsclone</title>
        <meta name="description" content="We are the best blockchain development company offering custom blockchain solutions that meet industry standards. Our expertise includes Ethereum, Solana, Avalanche, and more." />
        <meta name="keywords" content="Blockchain development company, Blockchain Development Services, Blockchain Technology Consulting, Blockchain Technology, Customized Blockchain Solutions, Customized blockchain-based apps, Blockchain-based Solutions, Blockchain Development Solutions, Blockchain-based applications, Blockchain Consulting Services, Blockchain development partner" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Blockchain Development Company | Coinsclone" />
        <meta property="og:description" content="We are the best blockchain development company offering custom blockchain solutions that meet industry standards. Our expertise includes Ethereum, Solana, Avalanche, and more." />
        <meta property="og:url" content="https://www.coinsclone.com/blockchain-development-company/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/blockchain/blockchain-development-company.webp" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="We are the best blockchain development company offering custom blockchain solutions that meet industry standards. Our expertise includes Ethereum, Solana, Avalanche, and more." />
        <meta name="twitter:title" content="Blockchain Development Company | Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/blockchain/blockchain-development-company.webp" />
      </Helmet>
      
      <BannerCrypto />
      <div className="breadcrumb wallet-banner mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | Blockchain Development Company </span>
        </div>
      </div>
      <HappyClient />
      <WeFocused />
      <Popular />
      <OurVarious />
      <OurCustom />
      <BenifitsOf />
      <IndustriesWe />
      <OnthSpot />
      <WhatMake />
      <Kickoff />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default BlockChain
