import React from "react"

class BenifitsOf extends React.Component {

  render() {
    return (

      <section className="process-of white-wallet benifits pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center">
              <span className="bluecolor">Exemplary Benefits</span> Of Blockchain Development
            </h2>
            <div className="d-lg-flex flex-wrap">
              <div className="square" >
                <h4>Decentralization</h4>
                <p className="pharagraph">
                Blockchain operates in a decentralized manner that assures no single point of failure, improves data security, and optimizes blockchain resources without a need for a central authority. Thus it gives you complete control and security irrespective of business category. 
                </p>
              </div>
              <div className="square">
                <h4>Transparency </h4>
                <p className="pharagraph">
                Blockchain data is transparent and tamper-proof but unalterable by nature. These qualities create trust among users and allow every user of the network to access the transaction details transparently. So you can keep great user engagement.
                </p>
              </div>
              <div className="square" >
                <h4>Smart Contracts</h4>
                <p className="pharagraph">
                Smart contracts are self-executing programs that automate blockchain operations in a trustworthy manner. They eliminate the need for supervision and improve platform operations via automation as defined in their coding.
                </p>
              </div>
              <div className="square" >
                <h4>Blockchain Efficiency</h4>
                <p className="pharagraph">
                Blockchain Efficiency is achieved with high-speed transaction verification and quicker operations that eliminate the delays and costs in time-consuming approaches. Also, blockchain transactions are more accurate and authentic, saving you time and money.
                </p>
              </div>
              <div className="square" >
                <h4>Data Security</h4>
                <p className="pharagraph">
                In blockchain data security is validated with advanced cryptography techniques and consensus algorithms at every transaction. Blockchain networks maintain integrity by keeping the data immutable and unchangeable at any cost.
                </p>
              </div>
              <div className="square" >
                <h4>Interoperability</h4>
                <p className="pharagraph">
                The interoperability feature allows the blockchain platform to easily communicate with other blockchain networks. This promotes expanded collaboration between different systems which gives you boosted business opportunities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BenifitsOf
