import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class OnthSpot extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
              <h4 className="heading-h2 text-center"><span className="bluecolor">What Makes Coinsclone </span> the Best Blockchain Development Company?</h4>
              <p className='pharagraph text-center'>Coinsclone is a well-experienced, leading Blockchain Development Company that is entirely capable of turning your blockchain aspirations into reality. As your reliable partner, we offer best-in-class blockchain development services that can be customized to match your requirements. With a track record of excellence, Coinsclone provides enterprise blockchain development solutions serving a diverse range of industries.</p>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side" >
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/blockchain/on-the-spot.webp"
                alt="On the spot hire blockchain developers"
                placeholder='none'
                width={500}
              />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">Our motto of "Empowering businesses today by adopting tomorrow’s futuristic technology" is reflected in every aspect of the services we offer.  With greater access to trending technological tools and market trends, our seasoned blockchain developers focus on your vision to meet project end goals. Having more than 100+ successful project completions and several active projects, our company’s portfolio will have a strong say in the quality of our service. Join the list of satisfied clients who have experienced the Coinsclone’s effect. From concept to deployment, we're with you every step of the way, ensuring your project stands apart in this competitive landscape.
              </p>
              <b>Choose Coinsclone today to explore the ultimate potential of blockchain technology!</b>
              <div className='mt-4 d-flex flex-wrap justify-content-center on-the'>
                <p>On-time Project delivery without delay</p>
                <p>24/7 dedicated support and guidance</p>
                <p>100% secure and scalable platforms</p>
                <p>One Place Solution from Ideation to Implementation</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default OnthSpot