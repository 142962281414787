import React from 'react'



class IndustriesWe extends React.Component {


  render() {

    return (
      <section className="induswe pt-100 mb-0">   
        <div className="container">
          <h3 className="heading-h2 text-center"><span className='bluecolor'>Industries We Serve</span> With Our Blockchain Development Services</h3>
          <p className='text-center'>Transform your blockchain ideas into business visions with our blockchain development solutions that revolutionize diverse industries.</p>
            <div className='d-flex flex-wrap justify-content-center ind-std'>
                <p>FinTech</p>
                <p>Real Estate</p>
                <p>Healthcare</p>
                <p>Education</p>
                <p>Entertainment</p>
                <p>Agriculture</p>
                <p>Supply chain</p>
                <p>Energy & Resources</p>
                <p>Travel & Tourism</p>
            </div>
        </div>
      </section>
    )
  }
}

export default IndustriesWe