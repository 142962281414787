import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side mb-0 mb-md-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor d-block'>Blockchain</span> Development Company</h1>
                <p className="pharagraph">Partner up with Coinsclone - a leading blockchain development company to experience the full potential of blockchain technology. With our professional blockchain developers and in-class blockchain development services, we offer expert assistance to bring your digital ideas into reality. From smart contracts to fully developed decentralized applications, we empower businesses to stay ahead and thrive in terms of the digital era.</p>
                <p>Coinsclone - We create business for the future!!</p>
                <div className="cta-banner">
              <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
          </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/blockchain/blockchain-development-company.webp"
                alt="Blockchain development company"
                className='d-none d-md-block'
                width={493}
                imgClassName='m-auto'
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection