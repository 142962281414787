import React from 'react'


const OurCustom = () => {


  return (
    <section className="customs pt-100 mb-0">
      <div className="container">
        <div className="row">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Our Custom</span> Blockchain Development Process
            </h2>
            <div className='d-flex flex-wrap justify-content-center top'>
                <div className='custom-blk'>
                    <p className="head3">Define</p>
                    <p>Get started on your journey with a blockchain-based solutions development company by defining the project's mission and vision. Make requirements ready, prepare services to deliver, choose target users and their demands, and conceptualize the design aspects.</p>
                </div>
                <div className='custom-blk'>
                    <p className="head3">Design</p>
                    <p>Make sure the platform design looks sleek to catch users' attention, and their layout, and color patterns are appealing. Keep the user engaged with features and ensure controls are aligned to enhance the user interface.</p>
                </div>
                <div className='custom-blk'> 
                    <p className="head3">Development</p>
                    <p>Proceed with the regulated process and keep track of each development phase to verify the quality standards by using innovative tools. Get the efforts verified by expert guidance At every stage.</p>
                </div>
            </div>
            <div className='d-flex flex-wrap justify-content-center bottom'>
                <div className='custom-blk'>
                    <p className="head3">Debug</p>
                    <p>Test your blockchain’s efficiency and performance with designated tools to ensure users flaw-free functionality. Maximize the security and credibility of the blockchain network with periodical supervision and upgrades.</p>
                </div>
                <div className='custom-blk'>
                    <p className="head3">Deployment</p>
                    <p>Prepare the technical requirements and outline for the strategic implementation to execute the plan, and align resources needed for deploying the platform within the blockchain while fulfilling the enterprise goal precisely.</p>
                </div>
            </div>
        </div>
        <a href="/contact-us/" className="bluebtn mt-3 mx-auto d-table">Get a Free Demo</a>
      </div>
    </section>
  )
}

export default OurCustom