import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons'

class HappyClient extends React.Component {

  render() {
    return (

      <section className="happy mb-0">
          <div className="container">
             <div className="row flex-wrap justify-content-center">
                <div className="client">
                    <p>150+</p>
                    <p className="mb-0">Happy Clients</p>
                </div>
                <div className="client">
                    <p>7+</p>
                    <p className="mb-0">Years of Experience</p>
                </div>
                <div className="client">
                    <p>200+</p>
                    <p className="mb-0">Successful Projects</p>
                </div>
                <div className="client">
                    <p><FontAwesomeIcon icon={faClock} /></p>
                    <p className="mb-0">On-time Delivery</p>
                </div>
             </div>
          </div>
      </section>

    )
  }
}

export default HappyClient
